import React, { FC } from "react";
import {Link, graphql } from "gatsby";
import { FacebookProvider, Comments } from "react-facebook";
import { BlogPostJsonLd, GatsbySeo } from "gatsby-plugin-next-seo";
import Img from "gatsby-image";
import { GrFacebook } from "react-icons/gr";
import { GrTwitter } from "react-icons/gr";
import { GrLinkedin } from "react-icons/gr";



const Article: FC = ({ data }: any, slug, nexttitle, nextslug, prevtitle, prevslug, some) => {
  
  console.log(data)

  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return (
    <React.Fragment>
      <GatsbySeo
        title={`${data.datoCmsBlog.title} | Casa Fraine`}
        description={data.datoCmsBlog.excerpt}
        canonical={`https://www.casafraine.com/blog/${data.datoCmsBlog.slug}`}
        openGraph={{
          url: `https://www.casafraine.com/blog/${data.datoCmsBlog.slug}`,
          title: `${data.datoCmsBlog.title}`,
          description: `${data.datoCmsBlog.excerpt}`,
          images: [
            {
              url: `${data.datoCmsBlog?.hero.fluid.src}`,
              width: 800,
              height: 600,
              alt: `${data.datoCmsBlog.title}`,
            },
            {
              url: `${data.datoCmsBlog.hero.fluid.src}`,
              width: 900,
              height: 800,
              alt: `${data.datoCmsBlog.title}`,
            },
            { url: `${data.datoCmsBlog.hero.fluid.src}` },
            { url: `${data.datoCmsBlog.hero.fluid.src}` },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "www.casafraine.com",
          cardType: "summary_large_image",
        }}
      />

      <BlogPostJsonLd
        url={`https://www.casafraine.com/blog/${data.datoCmsBlog.slug}`}
        title={`${data.datoCmsBlog.title} | Casa Fraine`}
        images={[data.datoCmsBlog.hero.fluid.src]}
        datePublished={data.datoCmsBlog.meta.createdAt}
        authorName="Fronte popolare contadino"
        description={data.datoCmsBlog.excerpt}
      />

      <section className="container-content">
        <article style={{ margin: "0" }} className="article">
          <h1>{data.datoCmsBlog.title}</h1>
          <Img
            className="img__article"
            fluid={data.datoCmsBlog.hero.fluid}
            alt={data.datoCmsBlog.title}
          />
          <div className="author-image text-center">
            Foto di&nbsp;
            <a
              target="_blank"
              rel="canonical noopener noreferrer"
              href="https://unsplash.com"
            >
              {data.datoCmsBlog.authorImage}
            </a>
          </div>
          <br />
          <time dateTime={data.datoCmsBlog.meta.createdAt}>
            {new Date(
              data.datoCmsBlog.meta.createdAt
            ).toLocaleString("it-IT", options)}
          </time>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: `${data.datoCmsBlog.descriptionNode.childMarkdownRemark.html}`,
            }}
          ></div>
          <FacebookProvider appId="167048748417291">
            <Comments
              href={`http://casafraine.com/blog/${data.datoCmsBlog.slug} `}
            />
          </FacebookProvider>
          <br />
          <br />
          <div style={{ display: "flex", maxWidth: "inherit", height: "fit-content", overflow: "scroll", overflowY: "hidden", maxHeight: "30rem" }}>
            {data.allDatoCmsBlog.edges.map((item, index) => {
              return (
                <>
                  <article style={{ marginRight: "4%", height: "fit-content" }} key={item.node.slug}>
                    <Img style={{ marginTop: "1rem", minWidth: "200px", height:"200px" }}
                      className="small__image"
                      fluid={item.node.hero.fluid}
                      alt={item.node.title}
                    />
                    <div  className="description">
                      <div  className="description__top">
                        <Link
                          key={index}
                          replace
                          to={`/blog/${item.node.slug}`}
                        >
                          <h5 style={{ marginBottom: "-1rem" }}>{item.node.title}</h5>
                          <br />
                          <time dateTime={item.node.meta.createdAt}>

                          </time>
                        </Link>


                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<small>${item.node.excerpt.substring(0, 110).trim() + "..."
                            }<small>`,
                        }}
                      ></div>
                    </div>
                  </article>
                </>
              );
            })}


          </div>
        </article>

      </section>
      <div className="share">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=https://www.casafraine.com/blog/${data.datoCmsBlog.slug}&amp;t=${data.datoCmsBlog.title}`}
          target="_blank"
          rel="noopener nofollow noreferrer"
          title="Share on facebook"
        >
          <GrFacebook style={{ color: "#4267b2", fontSize: "2rem" }} />
        </a>
        <a
          href={`https://twitter.com/share?url=https://www.casafraine.com/blog/${data.datoCmsBlog.slug}&text=${data.datoCmsBlog.title} by Vincenzo`}
          rel="noopener nofollow noreferrer"
          title="Share on Twitter"
          target="_blank"
        >
          <GrTwitter style={{ color: "#52a2f3", fontSize: "2rem" }} />
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.casafraine.com/blog/${data.datoCmsBlog.slug}&title=${data.datoCmsBlog.title}`}
          rel="noopener nofollow noreferrer"
          target="_blank"
          title="Share on Linkedin"
        >
          <GrLinkedin style={{ color: "#52a2f3", fontSize: "2rem" }} />
        </a>
      </div>

    </React.Fragment>
  );
};

export const query = graphql`
  query ArticleQuery($slug: String!) {
    datoCmsBlog(slug: { eq: $slug }) {
      title
      authorImage
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      category
      excerpt
      meta {
        createdAt
      }
      hero {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    
    }

    allDatoCmsBlog(filter: {}, limit: 10, skip: 1) {
      edges {
        node {
          title
          authorImage
          description
          slug
          category
          excerpt
          meta {
            createdAt
          }
          hero {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
        }
        }
        }
        
        
      
    
`;

export default Article;
